// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

function apiUrl(){
  var apiUrl ="";
  if(window.location.href.indexOf("localhost") > -1)
    return apiUrl = "https://localhost:7042/api/";
  else
    return apiUrl ="https://tahsilat.derkokozmetik.com/api/api/";
}
function apiContentUrl(){
  // var apiUrl ="";
  // if(window.location.href.indexOf("localhost") > -1)
  //   return apiUrl = "https://localhost:7042/";
  // else
  //   return apiUrl ="https://derkokozmetik.sedbim.com/api/";
}

export const environment = {
  production: false,
  hmr: false,
  apiUrl: apiUrl(),
  apiContentUrl: apiContentUrl(),
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
