import {AuthGuard} from "./shared/guards/auth.guards";




import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from 'app/shared/Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from 'app/shared/Layout/pages-layout/pages-layout.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'login',
    loadChildren: () => import('app/sign/sign.module').then(m => m.SignModule)
  },
  {
    path: '**',
    loadChildren: () => import('app/miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
  }];


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    useHash:true
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
