import { NgModule } from '@angular/core';
import { FeatherIconDirective } from './core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from './core-ripple-effect/core-ripple-effect.directive';

import { EllipsisOverflow } from './elllipsis-overflow/elipsis-overflow.directive';
import { ValueArrayPipe } from './valueArray/valueArray';

@NgModule({
  declarations: [EllipsisOverflow, ValueArrayPipe, RippleEffectDirective, FeatherIconDirective],
  exports: [EllipsisOverflow, ValueArrayPipe, RippleEffectDirective, FeatherIconDirective]
})
export class DirectivesModule {}
