import {NgModule} from "@angular/core";
import {PipesModule} from "./pipes/pipes.module";
import {DirectivesModule} from "./directives/directives.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from "./Layout/layout.module";

@NgModule({
  imports: [LayoutModule, PipesModule, DirectivesModule,NgbModule
  ],
  providers: [],
  exports: [LayoutModule, PipesModule, DirectivesModule,NgbModule]
})
export class SharedModule {
}


// important