import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AuthHelper {
    constructor() { }
    
    /**
     * Jwt parçalamak için kullanılır
     */
    private parseJwt(token: string): any {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        var json = JSON.parse(jsonPayload);
        return json;
    };

    /**
     * Aktif kullanıcın role listesini almak için kullanılır.
     */
    getRole(token:string): Array<string> {
        var roleList: Array<string> = new Array();
        var getJson = this.parseJwt(token);
        Object.entries(getJson).forEach((e: {}) => {
            if (e[0].includes('role')) {
                e[1].forEach(el => {
                    roleList.push(el);
                });
            }
        });
        return roleList;
    }
}