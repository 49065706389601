import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppComponent } from 'app/app.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { FooterComponent } from './Components/footer/footer.component';
import { SearchBoxComponent } from './Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Components/header/elements/user-box/user-box.component';
import { HeaderComponent } from './Components/header/header.component';
import { PageTitleComponent } from './Components/page-title/page-title.component';
import { LogoComponent } from './Components/sidebar/elements/logo/logo.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { PagesLayoutComponent } from './pages-layout/pages-layout.component';
import {RouterModule} from "@angular/router";

// BOOTSTRAP COMPONENTS

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule,
    LoadingBarRouterModule,
    RouterModule,
        // Angular Bootstrap Components

        PerfectScrollbarModule,
        NgbModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
  ],
  providers: [
    
  ],
  declarations: [
    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent
  ],
  exports: [
        // LAYOUT

        AppComponent,
        BaseLayoutComponent,
        PagesLayoutComponent,
        PageTitleComponent,
    
        // HEADER
    
        HeaderComponent,
        SearchBoxComponent,
        UserBoxComponent,
    
        // SIDEBAR
    
        SidebarComponent,
        LogoComponent,
    
        // FOOTER
    
        FooterComponent
  ]
})
export class LayoutModule {
}
