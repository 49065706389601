import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/shared/services';
import { AuthHelper } from '../helpers/auth';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _authHelper: AuthHelper) {
  }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      let roleList = this._authHelper.getRole(currentUser.token)
      let ctrlRole = false;
      if (route.hasOwnProperty('data')) {
        if(route.data.role != undefined || route.data.role != null){
          roleList.forEach(e => {
            e === route.data.role ? ctrlRole = true : ctrlRole = ctrlRole
          });
  
          if (!ctrlRole) {
            // this._router.navigate(['/pages/miscellaneous/not-authorized']);
            this._router.navigate(['/login']);
            return false;
          }
        }
      }

      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // this._router.navigate(['/pages/miscellaneous/not-authorized']);
        this._router.navigate(['/login']);
        return false;
      }
      return true;
    }

    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
